@import "~antd/dist/antd.less";

/* 自定义 antd date-picker  */
.custom-date-picker {
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  .ant-picker-input {
    display: none;
  }
  .ant-picker-range-separator {
    display: none;
  }
  .ant-picker-active-bar {
    display: none;
  }
}
// 自定义 antd popover 组件
.custom-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}

// checkbox 颜色
.ant-checkbox-inner {
  border-color: #d4d4d4;
}

// 修改 draw 圆角
.ant-drawer-content-wrapper,
.ant-drawer-content {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

// 主颜色
.primary-color {
  color: @primary-color;
}
.error-color {
  color: @error-color;
}

@primary-color: #1A7EF5;@link-color: #1A7EF5;@success-color: #15BC83;@error-color: #E84D4D;@border-color-base: #F0F0F0;@btn-font-size-sm: 12px;@btn-height-sm: 26px;@modal-border-radius: 10px;@btn-border-radius-base: 32px;@btn-border-radius-sm: 24px;@border-radius-base: 8px;@checkbox-border-radius: 2px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@menu-bg: #fcfcfc;@background-selected: #EDF6FF;@form-item-margin-bottom: 16px;@drawer-body-padding: 16px;@drawer-header-padding: 16px;@bg-light: #EDF6FF;@page-bg-light: #f0f3f7;