.btn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.74);
  background: rgba(0, 0, 0, 0.06);
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

@primary-color: #1A7EF5;@link-color: #1A7EF5;@success-color: #15BC83;@error-color: #E84D4D;@border-color-base: #F0F0F0;@btn-font-size-sm: 12px;@btn-height-sm: 26px;@modal-border-radius: 10px;@btn-border-radius-base: 32px;@btn-border-radius-sm: 24px;@border-radius-base: 8px;@checkbox-border-radius: 2px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@menu-bg: #fcfcfc;@background-selected: #EDF6FF;@form-item-margin-bottom: 16px;@drawer-body-padding: 16px;@drawer-header-padding: 16px;@bg-light: #EDF6FF;@page-bg-light: #f0f3f7;