body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  outline: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.font12 {
  font-size: 12px;
}

/* react-sortable-hoc ant table */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 99999;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}

/* 拖拽排序 https://github.com/clauderic/react-sortable-hoc/issues/803 */
.draggable-item {
  z-index: 99999;
}
